import { installationOrderService } from '@/api';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { DialogMixin } from '@/mixins/dialog';
import { PagingMixin } from '@/mixins/paging';
import { SelectInstallProductQuery, SelectInstallProductItem } from '@/resource/model';
import { messageError } from '@/utils';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectInstallProduct extends mixins(DialogMixin, PagingMixin) {
  public tableOptions: OsTableOption<SelectInstallProductItem> = {
    loading: false,
    data: [],
    fit: true,
    rowKey: (): string => {
      return 'itemId';
    }
  };

  public columnOptions: Array<OsTableColumn<SelectInstallProductItem>> = [
    {
      type: 'selection',
      prop: 'installShopItemRelId',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      showOverflowTooltip: true,
      minWidth: '180px'
    },
    {
      prop: 'surplusCount',
      label: 'addInstallationNotice.remainCount',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as SelectInstallProductItem).visibleWidth} × ${(row as SelectInstallProductItem).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as SelectInstallProductItem).finishWidth} × ${(row as SelectInstallProductItem).finishHeight}`;
      }
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public queryItemsOption: Array<OsQueryItemOption<SelectInstallProductQuery>> = [
    {
      type: 'Input',
      field: 'itemCode',
      label: 'projectProduct.itemCode',
      option: {
        placeholder: 'consumed.inputItemCode'
      },
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'pointName',
      label: 'projectProduct.pointName',
      option: {
        placeholder: 'projectProduct.inputPointName'
      },
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'platformProductName',
      label: 'projectProduct.platformProduct',
      option: {
        placeholder: 'consumed.inputPlatformProduct'
      },
      colSpan: 8
    },
    {
      type: 'Input',
      field: 'customerProductName',
      label: 'projectProduct.customerProduct',
      option: {
        placeholder: 'projectProduct.inputCustomerProduct'
      },
      colSpan: 8
    }
  ];

  @InjectReactive()
  public readonly shopId!: number;

  public queryForm: SelectInstallProductQuery = {
    installShopId: this.shopId
  };

  public selectedRows: Array<SelectInstallProductItem> = [];

  public dialogOpen(): void {
    this.getInstallProductItem();
  }

  public handleSelectionChange(selectedValues: Array<SelectInstallProductItem>): void {
    this.selectedRows = selectedValues;
  }

  public onSubmit(): void {
    this.$emit('select-confirm', this.selectedRows);
    this.closeDialog();
  }

  public get confirmDisabled(): boolean {
    return !(this.selectedRows.length > 0);
  }

  public reloadData(): void {
    (this.$refs.productTable as OsTable).clearSelection();
    this.paging.showCount = 1;
    this.getInstallProductItem();
  }

  public getInstallProductItem(): void {
    this.tableOptions.loading = true;
    this.queryForm.installShopId = this.shopId;
    installationOrderService
      .getInstallProductItem(this.queryForm)
      .then(res => {
        this.tableOptions.data = res.data || [];
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOptions.loading = false;
      });
  }
}

import { CommonTagService, installationOrderService } from '@/api';
import { OsAddress } from '@/components';
import {
  InstallationOrderInfo,
  InstallAttachmentsList,
  InstallEmployeeList,
  InstallProductItem,
  SelectInstallProductItem,
  ServiceItemList,
  UpdateInstallationOrder
} from '@/resource/model';
import { TagsViewModule } from '@/store/modules/tags-view';
import {
  convertProvinces,
  isNullOrUndefinedForBaseType,
  limitFutureForTimePicker,
  messageError,
  translation
} from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import SelectEmployee from '../../install-employee/select-employee/select-employee.vue';
import InstallAttachment from '../../installation-notice/install-attachment/install-attachment.vue';
import ProductDetails from './product-details/product-details.vue';
import SelectServiceItem from '@/views/dialogs/select-service-item/select-service-item.vue';
import { NormalSelectOptions } from '@/resource/model/common';
import { mobileReg, phoneReg } from '@/utils/validate';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'EditInstallationOrder',
  components: { SelectEmployee, InstallAttachment, ProductDetails, SelectServiceItem }
})
export default class EditInstallationOrder extends Vue {
  /**
   * 安装单ID
   */
  public id = Number(this.$route.query.id);

  public installationOrder: InstallationOrderInfo | null = null;

  public form: Partial<UpdateInstallationOrder> = {
    amount: 0,
    area: [],
    serveTime: [],
    serveStartTime: '',
    serveEndTime: '',
    remark: '',
    installTag: [],
    serveItems: '',
    planInstallTime: [],
    planInstallStartTime: '',
    planInstallEndTime: '',
    contacts: '',
    contactsTel: '',
    foremanName: '',
    foremanTel: '',
    foremanId: 0,
    address: '',
    abstractContent: ''
  };

  public formRules: { [prop in keyof Partial<UpdateInstallationOrder>]: Array<Object> } = {
    foremanName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('installationOrder.selectInstallEmployee')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    foremanTel: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputMobile')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!mobileReg(value)) {
            callback(new Error(this.$t('validate.mobileIllegal') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    contacts: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerShop.inputContact')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    address: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('installationOrder.inputAddress')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    contactsTel: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('personnel.inputMobile')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          if (!mobileReg(value) && !phoneReg(value)) {
            callback(new Error(this.$t('validate.mobileIOrPhonellegal') as string));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    serveItems: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('common.select')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    amount: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (isNullOrUndefinedForBaseType(value)) {
            callback(new Error(translation('addInstallationNotice.inputBudgetAmount')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    area: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('addDeliveryNotice.selectArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    serveTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addInstallationNotice.selectPlanInstallTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public tagList: NormalSelectOptions = [];

  public selectEmployeeVisible = false;

  public saveLoading = false;

  @ProvideReactive()
  public shopId = 0;
  // 选择服务项目
  public selectserviceItemDialog: boolean = false;

  private needReloadList = false;
  public openSelectserviceItemDialog(): void {
    this.selectserviceItemDialog = true;
  }

  public handleSelectServiceItem(serveItems: ServiceItemList): void {
    this.form.serveItems = serveItems.name;
  }

  public activated(): void {
    this.needReloadList = false;
    // 当id变化时，重新加载数据
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.getInstallationInfo(this.id);
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      this.getTagList();
      this.getInstallationInfo(this.id);
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/installation-order') {
      to.meta!.needReload = this.needReloadList;
    }
    next();
  }

  public openEmployeeDialog(): void {
    this.selectEmployeeVisible = true;
  }

  public handleSelectEmployeeConfirm(employee: InstallEmployeeList): void {
    this.form.foremanName = employee.realName;
    this.form.foremanId = employee.id;
    this.form.foremanTel = employee.mobile;
  }

  public syncInstallAttachment(data: Array<InstallAttachmentsList>): void {
    this.installationOrder!.installAttachmentList = data;
  }

  public batchDeleteProduct(toBeDeleteRows: Array<InstallProductItem>): void {
    const toBeDeleteIds = toBeDeleteRows.map(x => x.installShopItemRelId);
    this.installationOrder!.detailResponses =
      this.installationOrder!.detailResponses.filter(x => !toBeDeleteIds.includes(x.installShopItemRelId)) || [];
  }

  public batchAddProduct(items: Array<SelectInstallProductItem>): void {
    this.installationOrder!.detailResponses.push(...items);
  }

  public async save(): Promise<void> {
    try {
      const verifyRes = await this.verifyOrder();
      if (!verifyRes) {
        return;
      }
      this.handleFormData();
      await installationOrderService.put(this.form as UpdateInstallationOrder);
      Message.success(translation('operationRes.saveSuccess'));
      TagsViewModule.DelView(this.$route);
      this.needReloadList = true;
      this.$router.go(-1);
    } catch (error) {
      if (error) {
        messageError(error as any);
      }
    }
  }

  public async saveAndPush(): Promise<void> {
    try {
      const verifyRes = await this.verifyOrder();
      if (!verifyRes) {
        return;
      }
      this.handleFormData();
      await installationOrderService.putAndPush(this.form as UpdateInstallationOrder);
      Message.success(translation('operationRes.saveSuccess'));
      TagsViewModule.DelView(this.$route);
      this.needReloadList = true;
      this.$router.go(-1);
    } catch (error) {
      if (error) {
        messageError(error as any);
      }
    }
  }

  private getInstallationInfo(id: number): void {
    installationOrderService
      .getById(id)
      .then(res => {
        Object.assign(this.form, res);
        this.installationOrder = res;
        this.form.area = [
          this.installationOrder.province,
          this.installationOrder.city,
          this.installationOrder.district
        ].filter(item => !!item);
        // this.form.planInstallTime = [
        //   this.installationOrder.planInstallStartTime,
        //   this.installationOrder.planInstallEndTime
        // ];
        Vue.set(this.form, 'serveTime', [this.installationOrder.serveStartTime, this.installationOrder.serveEndTime]);
        this.form.installTag = JSON.parse(this.installationOrder.installTag);
        this.installationOrder.planInstallTime = [];
        this.shopId = res.installShopId;
        (this.$refs.osAddress as OsAddress)?.reload();
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async verifyOrder(): Promise<boolean> {
    if (this.installationOrder?.detailResponses.length === 0) {
      Message.error(translation('installationOrder.leastOneInstallProduct'));
      return Promise.reject();
    }
    return await (this.$refs.formRefs as ElForm).validate();
  }

  private handleFormData(): void {
    Object.assign(this.form, convertProvinces(this.form.area!));
    this.form.detailRequest = this.installationOrder!.detailResponses.map(x => {
      return {
        count: x.count,
        id: x.id,
        installShopItemRelId: x.installShopItemRelId,
        remark: x.remark
      };
    });

    this.form.fileIdList = this.installationOrder!.installAttachmentList.map(x => x.id);

    // if (this.form.planInstallTime) {
    //   this.form.planInstallStartTime = this.form.planInstallTime[0];
    //   this.form.planInstallEndTime = this.form.planInstallTime[1];
    // }
    if (this.form.serveTime) {
      this.form.serveStartTime = this.form.serveTime[0];
      this.form.serveEndTime = this.form.serveTime[1];
    }

    this.form.installTag = JSON.stringify(this.form.installTag);
  }

  private async getTagList(): Promise<void> {
    try {
      this.tagList = JSON.parse(await CommonTagService.getTagList()).map((x: string, i: number) => {
        return {
          label: x,
          value: i
        };
      });
    } catch (error) {
      messageError(error);
    }
  }
}

import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { InstallProductItem, SelectInstallProductItem } from '@/resource/model';
import { translation } from '@/utils';
import { Message } from 'element-ui';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SelectInstallProduct from './select-install-product/select-install-product.vue';

@Component({
  components: { SelectInstallProduct }
})
export default class ProductDetails extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => {
      return [];
    }
  })
  public products!: Array<InstallProductItem>;

  public tableOptions: OsTableOption<Partial<InstallProductItem>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true
  };

  public columnOptions: Array<OsTableColumn<InstallProductItem>> = [
    {
      type: 'selection',
      prop: 'installShopItemRelId',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '300px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleHeight',
      label: 'projectProduct.visibleSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as InstallProductItem).visibleWidth} × ${(row as InstallProductItem).visibleHeight}`;
      }
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as InstallProductItem).finishWidth} × ${(row as InstallProductItem).finishHeight}`;
      }
    },
    {
      prop: 'count',
      label: 'addInstallationNotice.installCount',
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.selectProduct',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: this.openAddProductDialog
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: '',
      plain: true,
      disabled: true,
      handleClick: this.batchDeleteProduct
    }
  ];

  public selectProductDialog = false;

  public selectedProducts: Array<InstallProductItem> = [];

  private readonly canEditCells: Array<Partial<keyof InstallProductItem>> = ['count', 'remark'];

  public handleProductSelection(products: Array<InstallProductItem>): void {
    this.selectedProducts = products;
  }

  public showInstallCount(
    installationProduct: InstallProductItem & {
      countInput: boolean;
    }
  ): void {
    installationProduct.countInput = true;
  }

  public handleInstallCountBlur(
    installationProduct: InstallProductItem & {
      countInput: boolean;
    }
  ): void {
    if (Number(installationProduct.count) < 1) {
      Message.error(`${translation('addInstallationNotice.installNumberLessOne')}`);
      installationProduct.count = 1;
    }

    if (!installationProduct.count) {
      installationProduct.count = 0;
      installationProduct.countInput = !installationProduct.countInput;
      return;
    }

    if (Number(installationProduct.count) > installationProduct.surplusCount) {
      Message.error(`${translation('addInstallationNotice.remainInstallCount')}${installationProduct.surplusCount}`);
      installationProduct.count = installationProduct.surplusCount;
    }

    installationProduct.countInput = false;
  }

  public showRemarkInput(
    installationProduct: InstallProductItem & {
      remarkInput: boolean;
    }
  ): void {
    installationProduct.remarkInput = true;
  }

  public hideRemarkInput(
    installationProduct: InstallProductItem & {
      remarkInput: boolean;
    }
  ): void {
    installationProduct.remarkInput = false;
  }

  public handleAddProductConfirm(items: Array<SelectInstallProductItem>): void {
    const addedIds = this.tableOptions.data.map(x => x.installShopItemRelId);
    const toAddItems = items.filter(x => !addedIds.includes(x.installShopItemRelId));
    toAddItems.forEach(x => {
      this.initInputDynamicProp(x, this.canEditCells);
    });
    this.$emit('batch-add', toAddItems);
  }

  @Watch('products', { immediate: true })
  private loadData(): void {
    this.products.forEach(x => {
      this.initInputDynamicProp(x, this.canEditCells);
    });
    this.tableOptions.data = this.products;
  }

  private openAddProductDialog(): void {
    this.selectProductDialog = true;
  }

  private batchDeleteProduct(): void {
    this.$emit('batch-delete', this.selectedProducts);
    (this.$refs.productTable as OsTable).clearSelection();
    this.selectedProducts = [];
  }

  @Watch('selectedProducts')
  private handleSelectedChanged(value: Array<InstallProductItem>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType === 'batchDelete') {
        x.disabled = value.length === 0;
      }
    });
  }

  /**
   * 初始化项次内，部分可以动态使用input编辑的属性
   * @param rowObj行对象
   * @param canEditCells 要初始化的列
   */
  private initInputDynamicProp(rowObj: any, canEditCells: Array<string>): void {
    canEditCells.forEach(x => {
      Vue.set(rowObj, `${x}Input`, false);
    });
  }
}
